import React, { useState, useEffect } from "react";
import videoBanner from "./images/blue particles.mp4";
import videoBannerVertical from "./images/vertical.mp4";
import videoBannerVerticalGif from "./images/vertical.gif";

function Banner() {
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    // Detectar si el dispositivo es iOS
    setIsIos(/iPhone|iPad|iPod/i.test(navigator.userAgent));
  }, []);

  return (
    <div className="banner" id="banner">
      {isIos ? (
        <div className="banner__video">
          <img
            src={window.innerWidth > 867 ? videoBanner : videoBannerVerticalGif}
            alt="Vertical Gif"
            className="banner__video-gif"
          />
        </div>
      ) : (
        <video className="banner__video" muted autoPlay loop>
          <source
            src={window.innerWidth > 867 ? videoBanner : videoBannerVertical}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="banner__title-container">
        <h1 className="banner__title">DANTE'S CLOSET</h1>
        <p className="banner__subtitle">JUEGA CON TU AMBICIÓN</p>
      </div>
    </div>
  );
}

export default Banner;
