import React from "react";
import footerArrow from "./images/arrow_upward_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.png";

function Footer() {
  return (
    <div className="footer reveal">
      
      <div className="footer__container">
      <div className="footer__overlay"></div>
        <div className="footer__title-container"></div>
        <h3 className="footer__title">Dante's Closet</h3>
        <p className="footer__subtitle">© 2024. All Rights Reserved.</p>
        <div className="footer__button-container">
            <a href="#banner" className="footer__link">
            <img src={footerArrow} alt="" className="footer__button" /> 
            </a>
         
        </div>
      </div>
    </div>
  );
}

export default Footer;
