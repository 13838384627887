import React, { useState } from 'react';
import share from "./images/share_24dp_WHITE_FILL0_wght400_GRAD0_opsz24.png";
import contactUs from "./images/chat_24dp_WHITE_FILL0_wght400_GRAD0_opsz24.png";
import visitUs from "./images/map_24dp_WHITE_FILL0_wght400_GRAD0_opsz24.png";
import whatsApp from "./images/whatsApp (2).png";
import facebook from './images/facebook.png';

function Contact() {

  

  const [activeContainer, setActiveContainer] = useState(null);

 const renderContainer = () => {
    switch(activeContainer) {
      case 'contactUs':
        return <div className="contact__media-cards-container">
          <a href="https://api.whatsapp.com/send?phone=+528331802600&text=Vi%20tu%20anuncio%20y%20me%20interesa" className="contact__link">
          <img src={whatsApp} alt="" className="contact__media-card" />
          </a>
          
           <span className="contact__media-cards-text">
                            <span className="text-content">WHATSAPP
                            </span>
                        </span>

        </div>
       
      case 'social':
        return <div className="contact__media-cards-container">
          <a href="https://www.facebook.com/profile.php?id=61552475878650" className="contact__link">
          <img src={facebook} alt="" className="contact__media-card" />
          </a>
      
        <span className="contact__media-cards-text">
                            <span className="text-content">FACEBOOK
                            </span>
                        </span>

     </div>
      case 'visitUs':
        return (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59076.963804372834!2d-97.91068497601167!3d22.26623495589363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d7fd8bfcfe2d1f%3A0xc3189ac39db089e6!2sTampico%2C%20Tamps.!5e0!3m2!1ses-419!2smx!4v1725132157700!5m2!1ses-419!2smx"
            width="50%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
       
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        );
      default:
        return <div className="contact__media-cards-container">
       <a href="https://www.facebook.com/profile.php?id=61552475878650" className="contact__link">
          <img src={facebook} alt="" className="contact__media-card" />
          </a>
        <span className="contact__media-cards-text">
                            <span className="text-content">FACEBOOK
                            </span>
                        </span>

     </div>
    }
  };

  return (
    <div className="contact reveal" id='contact'>
      <div className="contact__container">
        <div className="contact__title-container">
          <h3 className="contact__title">CONTACTO</h3>
        </div>

        <div className="contact__media-container">
          <div className="contact__media">
         
          <img onClick={() => setActiveContainer('social')} src={share} alt="" className="contact__media-img" />
          <h4 className="contact__media-title">Síguenos</h4>
          </div>
          
          <div className="contact__media">
        
          <img onClick={() => setActiveContainer('contactUs')} src={contactUs} alt="" className="contact__media-img" />
          <h4 className="contact__media-title">Contáctanos</h4>
            </div>
          
          <div className="contact__media">
          
          <img onClick={() => setActiveContainer('visitUs')} src={visitUs} alt="" className="contact__media-img" />
          <h4 className="contact__media-title">Visítanos</h4>
            </div>
         
        </div>
      </div>
      <div className="contact__media-content">
        {renderContainer()}
      </div>
    </div>
  );
}

export default Contact;
