import React from "react";
import headerLogo from './images/logo__name.png'

function Header() {
  return (
    <div className="header reveal">
      <div className="header__logo-container">
        <img className="header__logo" src={headerLogo} alt="Logo" />
      </div>
      <ul className="header__list-container"> {/* Correct 'ui' a 'ul' */}
        <a className="header__link" href="#offer">
        <li className="header__list">Oferta</li>
        </a>

        <a href="#process" className="header__link">
        <li className="header__list">Proceso</li>
        </a>

        <a href="#work" className="header__link">
        <li className="header__list">Trabajo</li>
        </a>
    
        <a href="#contact" className="header__link">
        <li className="header__list">Contáctanos</li> {/* Corrección ortográfica */}
        </a>
      </ul>
    </div>
  );
}

export default Header;
