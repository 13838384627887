import React from "react";
import whatsAppPicture from "./images/whatsApp (2).png"


function WhatsApp() {
  return (
    <a  href="https://api.whatsapp.com/send?phone=+528331802600&text=Vi%20tu%20anuncio%20y%20me%20interesa" className="whatsapp">
     
      <img src={whatsAppPicture} alt="" className="whatsApp__img" />
      


    </a>
  );
}

export default WhatsApp;
