import React from "react";
import offerpicture1 from "./images/1.jpg";
import offerpicture2 from "./images/Shutterstock_796662088.jpg";
import offerpicture3 from "./images/3.jpg";

function Offer() {
  return (
    <div className="offer reveal" id="offer">
      <h1 className="offer__title">DANTE´S CLOSET OFERTA COMERCIAL</h1> {/* Corrección ortográfica */}

      <div className="offer__container">
        <img src={offerpicture1} alt="" className="offer__picture" />
        <div className="offer__text-container">
          <h4 className="offer__subtitle">TRANSFORMAMOS TU AMBICIÓN EN ÉXITO</h4> {/* Corrección ortográfica */}
          <p className="offer__text">
          Nuestro equipo de expertos en marketing digital está totalmente comprometido en comprender su visión, identificar sus objetivos y convertirlos en una realidad rentable. Nos enfocamos en estrategias personalizadas que maximizan su presencia en línea, impulsan el crecimiento de su marca y generan resultados medibles. Con un enfoque basado en datos, creatividad e innovación, transformamos su ambición en éxito, ayudándole a conectar de manera efectiva con su público objetivo y a destacar en un mercado cada vez más competitivo.
          </p>
        </div>
      </div>

      <div className="offer__container">
        <div className="offer__text-container">
          <p className="offer__text">
            No importa si usted está al mando de un prometedor start-up, es un experimentado profesional en busca de ampliar su presencia en línea, o si dirige una línea de manufactura de alta calidad, SEA LO QUE SEA tu pasión, tenemos las herramientas y el conocimiento para llevarlo al siguiente nivel. {/* Corrección ortográfica */}
          </p>
        </div>
        <img src={offerpicture2} alt="" className="offer__picture" />
      </div>
    </div>
  );
}

export default Offer;
