import React, { useState } from "react";
import processimage1 from "./images/process1.jpg";
import processimage2 from "./images/process2.jpg";
import processimage4 from "./images/process4.jpg";
import back from "./images/arrow_back_ios_16dp_WHITE_FILL0_wght400_GRAD0_opsz20.png";
import next from "./images/arrow_forward_ios_16dp_WHITE_FILL0_wght400_GRAD0_opsz20.png";
import backgroundimage from "./images/offer1.png";

function Process() {

    const images = [
        { id: 1, src: processimage1, alt: "Image 1", text: "1. ESCUCHAMOS TU AMBICION." },
        { id: 2, src: processimage2, alt: "Image 2", text: "2. TE PLANTEAMOS LA SOLUCION" },
        { id: 3, src: processimage4, alt: "Image 3", text: "3. LA SUBIMOS EN LINEA" }
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="process reveal" id="process">
            <div className="process__container">
                <div className="process__overlay"></div> {/* Capa oscura */}
                <div className="process__content">
                    <div className="process__back-container">
                        <img src={back} alt="Atrás" className="process__back" onClick={goToPrevious} />
                    </div>
                    <div className="process__pictures-container">
                        <div className="process__title-container">
                            <h4 className="process__title">NUESTRO PROCESO</h4>
                        </div>
                        <img src={images[currentIndex].src} alt={images[currentIndex].alt} className="process__image" />
                        <div className="process__picture-container-text">
                            <p className="process__picture-text">{images[currentIndex].text}</p>
                        </div>
                    </div>
                    <div className="process__next-container">
                        <img src={next} alt="Adelante" className="process__next" onClick={goToNext} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;
